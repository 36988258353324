import React from 'react'
import { FormattedMessage } from 'react-intl'
import Header from '../../components/Sections/Header'
import './index.css'

const Documentation = ({ locale }) => {
    if (locale !== 'ru') return null

    return (
        <div className='app'>
            <Header />
            <main className='documentation-content'>
                <h1 className='documentation-title'>Документация</h1>
                <div className='documentation-list'>
                    <div className='documentation-item'>
                        <h2>Руководство пользователя</h2>
                        <p>Подробное описание функциональности системы и инструкции по работе с ней</p>
                        <a href='/docs/user-manual.pdf' className='documentation-link'>
                            Открыть руководство пользователя
                        </a>
                    </div>
                </div>
            </main>
            <footer className='footer'>
                <h2 className='footer-title'>
                    <FormattedMessage id='footer.title' />
                </h2>
                <a href='mailto:info@scanslated.ru' className='footer-email'>
                    <FormattedMessage id='footer.email' />
                </a>
                <div className='footer-bottom'>
                    <p className='footer-copyright'>
                        <FormattedMessage id='footer.copyright' />
                    </p>
                </div>
            </footer>
        </div>
    )
}

export default Documentation
